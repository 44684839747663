export default function Telegram() {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#5EBEE1"
        d="M512,397.1c0,63.5-51.5,114.9-114.9,114.9H114.9C51.5,512,0,460.5,0,397.1V114.9C0,51.5,51.5,0,114.9,0h282.2   C460.5,0,512,51.5,512,114.9V397.1z"
      />
      <path
        fill="#FFFFFF"
        d="M383.1,134.1L116.3,238c-8.6,3.4-8.1,15.8,0.8,18.4l67.8,20l25.3,80.3c2.6,8.4,13.3,10.9,19.4,4.6l35.1-35.8   l68.8,50.5c8.4,6.2,20.4,1.6,22.5-8.6l45.5-217.8C403.8,139,393.3,130.1,383.1,134.1L383.1,134.1L383.1,134.1z M349.7,182.4   L225.8,292c-1.2,1.1-2,2.6-2.2,4.2l-4.8,42.4c-0.2,1.4-2.1,1.6-2.5,0.2l-19.6-63.3c-0.9-2.9,0.3-6,2.9-7.6l146.3-90.8   C349.2,175.2,352.7,179.8,349.7,182.4L349.7,182.4L349.7,182.4z"
      />
    </svg>
  )
}
