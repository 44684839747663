export default function Viber() {
  return (
    <svg viewBox="0 0 28.87 28.87" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#7d3daf" height="28.87" rx="6.48" ry="6.48" width="28.87" />
      <path
        fill="#ffffff"
        d="M21.83,10.28v0a5.34,5.34,0,0,0-3.6-3.47h0a20,20,0,0,0-7.54,0h0A5.34,5.34,0,0,0,7,10.26v0a14.75,14.75,0,0,0,0,6.35v0a5.38,5.38,0,0,0,3.4,3.42v1.69a.68.68,0,0,0,1.17.47l1.71-1.77c.37,0,.74,0,1.11,0a20.09,20.09,0,0,0,3.77-.36h0a5.34,5.34,0,0,0,3.6-3.47v0A14.75,14.75,0,0,0,21.83,10.28Zm-1.35,6a4,4,0,0,1-2.54,2.44,18.66,18.66,0,0,1-4,.32.09.09,0,0,0-.07,0L12.61,20.4l-1.33,1.36a.16.16,0,0,1-.27-.11V18.86a.1.1,0,0,0-.08-.09h0a4,4,0,0,1-2.54-2.44,13.38,13.38,0,0,1,0-5.74,4,4,0,0,1,2.54-2.44,18.64,18.64,0,0,1,7,0,4,4,0,0,1,2.54,2.44A13.37,13.37,0,0,1,20.48,16.33Z"
      />
      <path
        fill="#ffffff"
        d="M17.82,15.84a7.84,7.84,0,0,0-.65-.49c-.23-.16-.45-.32-.69-.47a.61.61,0,0,0-.73,0c-.22.18-.33.53-.64.57a1,1,0,0,1-.52-.13,3.62,3.62,0,0,1-2-2.25.45.45,0,0,1,0-.4.69.69,0,0,1,.16-.17l.21-.16a.65.65,0,0,0,.18-.88,8.08,8.08,0,0,0-1-1.36.5.5,0,0,0-.63-.15,2.34,2.34,0,0,0-.78.59,1,1,0,0,0-.3,1c.16.39.32.78.5,1.16a9.85,9.85,0,0,0,1.45,2.22,10.06,10.06,0,0,0,3.79,2.54c.14.06.29.09.44.14a2.23,2.23,0,0,0,.4-.13,2.15,2.15,0,0,0,.93-1A.51.51,0,0,0,17.82,15.84Z"
      />
      <path
        fill="#ffffff"
        d="M14.44,9.49h-.11a.23.23,0,0,0-.23.26c0,.17.2.17.34.19s.43,0,.63.07a3.35,3.35,0,0,1,2.7,2.8c0,.26.05.53.07.8s.08.26.23.26a.22.22,0,0,0,.21-.26c0-.32,0-.64-.1-1A3.81,3.81,0,0,0,14.44,9.49Z"
      />
      <path
        fill="#ffffff"
        d="M16.85,13.15a.57.57,0,0,0,0,.18.21.21,0,0,0,.4,0,.87.87,0,0,0,0-.13c0-.13,0-.23,0-.32s0-.08,0-.12a2.48,2.48,0,0,0-1.35-1.93,2.94,2.94,0,0,0-1.23-.32.2.2,0,0,0-.22.2.2.2,0,0,0,.19.23L15,11a2.56,2.56,0,0,1,.71.22,2.12,2.12,0,0,1,.89.83A2.27,2.27,0,0,1,16.85,13.15Z"
      />
      <path
        fill="#ffffff"
        d="M16.29,13a.59.59,0,0,0,0-.18A1.29,1.29,0,0,0,15,11.52a.23.23,0,0,0-.24.18.21.21,0,0,0,.17.25c.11,0,.23,0,.35.05a.81.81,0,0,1,.25.12.84.84,0,0,1,.31.49,2.34,2.34,0,0,1,0,.3.23.23,0,0,0,.24.23h0A.19.19,0,0,0,16.29,13Z"
      />
    </svg>
  )
}
